import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hey, what's up!👋`}</h1>
    <p>{`I'm DeAndre, a social impact-driven full stack developer with a passion for technology. I also have a background in graphic design, marketing, and education. I'm a completely self-taught software developer and still learning.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      